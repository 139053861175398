import React, { Component } from 'react';
import '../assets/css/channel.scss'
import store from '../store'
// import axios from 'axios'
import { _getHy, _subFuwu, _checkPhone } from '../assets/js/request'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      goodList: [
        {
          title: '海量资源',
          img: require('../assets/img/channel/channel_icon1.png')
        }, {
          title: '市场巨大',
          img: require('../assets/img/channel/channel_icon2.png')
        }, {
          title: '丰厚返利',
          img: require('../assets/img/channel/channel_icon3.png')
        }, {
          title: '培训支持',
          img: require('../assets/img/channel/channel_icon4.png')
        }, {
          title: '市场支持',
          img: require('../assets/img/channel/channel_icon5.png')
        }, {
          title: '驻地支持',
          img: require('../assets/img/channel/channel_icon6.png')
        }
      ],
      wayList: [
        {
          title: '具备公司资质',
          text: '具有合法营业执照和独立法人代表，有良好的商业信誉和口碑',
          img: require('../assets/img/channel/channel_pic1.png')
        }, {
          title: '具有销售团队',
          text: '专职销售团队，具有互联网或SaaS领域的代理或销售经营背景',
          img: require('../assets/img/channel/channel_pic2.png')
        }, {
          title: '认可园区经营生态文化',
          text: '认同YUANOS的产品价值和文化理念，愿意跟YUANOS长期合作',
          img: require('../assets/img/channel/channel_pic3.png')
        }
      ],
      stepList: [
        {
          text: '提交申请',
          img: require('../assets/img/channel/channel_step1.png')
        }, {}, {
          text: '平台处理',
          img: require('../assets/img/channel/channel_step2.png')
        }, {}, {
          text: '洽谈方案',
          img: require('../assets/img/channel/channel_step3.png')
        }, {}, {
          text: '签约合作',
          img: require('../assets/img/channel/channel_step4.png')
        }
      ],
      name: '',
      phone: '',
      company_name: '',
      company_address: '',
      // company_scale: '',
      // taxpayer_qualification: '',
      nowHy: '', // industry
      nowHid: '', // industry
      hyList: [],
      hyPopOff: false,
      rightPhone: ''
    }
  }
  cancelHy() {
    if (this.state.hyPopOff) {
      this.setState({
        hyPopOff: false
      })
    }
    
  }
  showHy(event) {
    this.setState({
      hyPopOff: !this.state.hyPopOff
    })
    // if (event && event.stopPropagation) {
    //   event.stopPropagation();
    // }else{
    //   window.event.cancelBubble = true;
    // }
  }
  chosehy(e, val) {
    // e.stopPropagation()
    this.setState({
      nowHy: val.name,
      nowHid: val.id
    })
  }
  componentDidMount() {
    // window.addEventListener('mousewheel', this.clear, false)
    window.onmousewheel = this.clear.bind(this)
    _getHy().then(res => {
      this.setState({
        hyList: res
      })
    })
  }
  componentWillUnmount() {
    window.onmousewheel = null
    // window.removeEventListener('mousewheel', this.clear, false)
  }
  clear() {
    if (this.scrollTimer) {
      clearTimeout(this.scrollTimer)
    }
  }
  // 输入信息
  handlePut(key, e) {
    this.setState({
      [key]: e.target.value
    }, () => {
      if (key === 'phone') {
        if ((/^1[3456789]\d{9}$/.test(this.state.phone))) { 
          _checkPhone({
            data: {
              phone: this.state.phone,
              state: 1
            }
          }).then(res => { 
            this.setState({
              rightPhone: ''
            })
          }).catch(err => {
            if (err.match('已存在')) {
              this.setState({
                rightPhone: '该手机号已提交过申请'
              })
            } else {
              this.setState({
                rightPhone: '该手机号当前不可申请'
              })
            }
          })
        } else {
          this.setState({
            rightPhone: ''
          })
        }
        
      }
    })
   
  }
  // 下滑到表单
  goSub() {
    var pChannel_from = document.getElementById('pChannel_from')
    // window.scrollTo(0, pChannel_from.offsetTop)
    this.scrollAnimation(0, pChannel_from.offsetTop)
  }
  scrollAnimation(currentY, targetY) {
    // 获取当前位置方法
    // const currentY = document.documentElement.scrollTop || document.body.scrollTop
   
    // 计算需要移动的距离
    let needScrollTop = targetY - currentY
    let _currentY = currentY
    this.scrollTimer = setTimeout(() => {
      // 一次调用滑动帧数，每次调用会不一样
      const dist = Math.ceil(needScrollTop / 10)
      _currentY += dist
      window.scrollTo(_currentY, currentY)
      // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
      if (needScrollTop > 10 || needScrollTop < -10) {
        this.scrollAnimation(_currentY, targetY)
      } else {
        window.scrollTo(_currentY, targetY)
      }
    }, 10)
  }
  submitform(e) {
		e.preventDefault();
    let check = this.check()
    if (check) {
      this.showToast(check)
    } else {
      _subFuwu({
        data: {
          name: this.state.name,
          phone: this.state.phone,
          company_name: this.state.company_name,
          company_address: this.state.company_address,
          // company_scale: this.state.company_scale,
          // taxpayer_qualification: this.state.taxpayer_qualification,
          industry: this.state.nowHid,
        }
      }).then(res => {
        store.dispatch({
          type: 'toggleDialog',
          value: true,
          text: '您的信息已提交，我们有专门的客服联系您'
        })
        this.setState({
          name: '',
          phone: '',
          company_name: '',
          company_address: '',
          // company_scale: '',
          // taxpayer_qualification: '',
          nowHy: '',
          nowHid: ''
        })
        
        // this.showToast('提交成功')
      })
    }
    
    return false
  }
  // 校验表单
  check() {
    if (!this.state.name) {
      return '请填写姓名'
    } else if (!this.state.phone) {
      return '请填写手机号'
    } else if (!(/^1[3456789]\d{9}$/.test(this.state.phone))) {
      return '请填写正确的手机号'
    } else if (!this.state.company_name) {
      return '请填写公司姓名'
    } else if (!this.state.company_address) {
      return '请填写公司地址'
    }
    // else if (!this.state.company_scale) {
    //   return '请填写公司规模'
    // } else if (!this.state.taxpayer_qualification) {
    //   return '请填写纳税人资格'
    // }
    else if (!this.state.nowHid) {
      return '请选择行业'
    } else {
      return false
    }
  }
  showToast(value) {
    store.dispatch({
      type: 'showToast',
      value
    })
    setTimeout(() => {
      store.dispatch({type: 'hideToast'})
    }, 2000);
  }
  changeHy(e) {
    this.setState({
      nowHy: e.target.value
    })
  }
  render() {
    return (
      
      <div className="pChannel" onClick={ () => { this.cancelHy() } }>
        <div className="pChannel_banner">
          <img src={require('../assets/img/channel/channel_banner.png')} alt="" />
          <div className="pChannel_banner_con">
            <h6>代理火热招商中</h6>
            <div className="pChannel_banner_text">与代理商伙伴建立共赢发展模式，构建本地园区生态一体化平台</div>
            <div className="pChannel_banner_btn btn" onClick={ () => { this.goSub() } }>加入我们的服务商体系</div>
          </div>
        </div>


        <div className="pChannel_title actDom" data-actcls="fadeInUp">代理商六大优势
        <div className="title_subtitle">Advantage</div>
        </div>

        <ul className="pChannel_good actDom" data-actcls="fadeInUp">
          {
            this.state.goodList.map((item, index) => {
              return (
                <li key={ index }>
                  <img src={ item.img } alt="" />
                  <h6>{ item.title }</h6>
                </li>
              )
            })
          }
        </ul>

        <div className="pChannel_way">
          <div className="pChannel_title actDom" data-actcls="fadeInUp">代理商合作条件</div>
          <ul className="actDom" data-actcls="fadeInUp" data-lazypic="1">
          {
            this.state.wayList.map((item, index) => {
              return (
                <li key={ index }>
                  <img src="" data-src={item.img} alt="" />
                  <div className="pChannel_way_con">
                    <h6>{item.title}</h6>
                    <p>{ item.text }</p>
                  </div>
                </li>
              )
            })
          }
        </ul>
        </div>
        

        <div className="pChannel_title actDom" data-actcls="fadeInUp">代理商申请流程
        <div className="title_subtitle">process</div>
        </div>

        <div className="pChannel_step actDom"  data-actcls="fadeInUp" data-lazypic="1">
          {
            this.state.stepList.map((item, index) => {
              return item.text ? (
                <div className="pChannel_step_item" key={ index }>
                  <img src="" data-src={item.img} alt="" />
                  <p>{ item.text }</p>
                </div>
              ) : (
                <img key={ index } src={ require('../assets/img/channel/channel_right.png') } className="pChannel_step_next" alt=""/>
              )
            })
          }
        </div>


        <div className="pChannel_from" id="pChannel_from">
          <form>
            <div className="pChannel_from_three"></div>
            <h6 className="pChannel_from_title">立即申请</h6>
            <div className="pChannel_from_item actDom actDom" data-actcls="bounceInLeft">
              <div><span>*</span>姓名</div>
              <input type="text" placeholder="请填写您的姓名" value={ this.state.name } onChange={ this.handlePut.bind(this, 'name') } />
            </div>
            <div className="pChannel_from_item actDom" data-actcls="bounceInRight">
              <div><span>*</span>手机号</div>
              <input type="number" placeholder="请填写您的手机号" value={ this.state.phone } onChange={ this.handlePut.bind(this, 'phone') } />
            </div>
            {
              this.state.rightPhone ? <div className="pChannel_from_item_alert">{ this.state.rightPhone }</div> : ''
            }
            <div className="pChannel_from_item actDom" data-actcls="bounceInLeft">
              <div><span>*</span>公司全称</div>
              <input type="text" placeholder="请填写您的公司全称" value={ this.state.company_name } onChange={ this.handlePut.bind(this, 'company_name') } />
            </div>
            <div className="pChannel_from_item actDom" data-actcls="bounceInRight">
              <div><span>*</span>公司地址</div>
              <input type="text" placeholder="请填写您的公司地址" value={ this.state.company_address } onChange={ this.handlePut.bind(this, 'company_address') } />
            </div>
            {/* <div className="pChannel_from_item actDom" data-actcls="bounceInLeft">
              <div>公司规模</div>
              <input type="text" placeholder="请填写您的公司规模" value={ this.state.company_scale } onChange={ this.handlePut.bind(this, 'company_scale') } />
            </div>
            <div className="pChannel_from_item actDom" data-actcls="bounceInRight">
              <div>纳税人资格</div>
              <input type="text" placeholder="请填写您的纳税人资格" value={ this.state.taxpayer_qualification } onChange={ this.handlePut.bind(this, 'taxpayer_qualification') } />
            </div> */}
            <div className="pChannel_from_item actDom pChannel_from_itemHy" data-actcls="bounceInLeft" onClick={ (e) => { this.showHy(e) } }>
              <div><span>*</span>所属行业</div>
              <input type="text" disabled placeholder="请选择您的行业" value={this.state.nowHy}  />
              {/* <select style={{ color: this.state.nowHy ? '#061829' : '#666666' }} defaultValue="" onChange={(e) => this.changeHy(e)}>
                <option value="" disabled hidden>请选择行业</option>
                {
                  this.state.hyList.map((item, index) => {
                    return (
                      <option value={item} key={index}>{item}</option>
                    )   
                  })
                }
              </select> */}
              {
                this.state.hyPopOff ? <ul>
                {
                  this.state.hyList.map((item, index) => {
                    return (
                      <li key={index} onClick={ (e) => this.chosehy(e, item) }>{ item.name }</li>
                    )
                  })
                }
              </ul> : ''
              }
              
              <i></i>
            </div>
            <input type="submit" className="btn" value="提交" onClick={ (e) => { this.submitform(e) } } />
          </form>
          <div className="pChannel_from_img">
            <img src={ require('../assets/img/channel/channel_form_pic.png') } alt=""/>
          </div>
        </div>
      </div>				
		)
	}
}

export default Home