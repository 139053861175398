import React, { Component } from 'react';
import '../../assets/css/compent/banner.scss'
import store from '../../store'

class Banner extends Component {
  showPop() {
    store.dispatch({
      type: 'changePop_off',
      value: true
    })
  }
  render() {
		return(
      <div className="pBanner">
        <img src={this.props.img} alt="" className="pbanner_bg" />
        <div className="pbanner_con" style={{ color: this.props.color ? this.props.color : '#061829' }}>
          {
            this.props.titleImg ? <div className="pbanner_con_title">
              <img src={this.props.titleImg} alt=""/>
            </div> : <div className="pbanner_con_title">{this.props.title}</div>
          }
          {/* <div className="pbanner_con_title">{ this.props.title }</div> */}
          <div className="pbanner_con_text">
            {
              typeof(this.props.text) == 'object' ? (
                this.props.text.map((item, index) => {
                  return (
                    <p key={index}>{ item }</p>
                  )
                })
              ) : this.props.text
            }
          </div>
          <div className="pbanner_con_go btn" style={{ background: this.props.btncolor ? this.props.btncolor : '#FF4D00' }} onClick={ () => { this.showPop() } }>快速体验</div>
          <div className="pbanner_con_call">客服 18321375963</div>
        </div>
      </div>			
		)
	}
}

export default Banner