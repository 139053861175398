import React, { Component } from 'react';
import '../../assets/css/home.scss'
import store from '../../store'

class Foot extends Component {
  constructor(props) {
    super(props)
    console.log(props)
  }
  showPop() {
    store.dispatch({
      type: 'changePop_off',
      value: true
    })
  }
  render() {
		return(
      <div className="pFoot">
        {
          this.props.nowPage !== '/park/channel' ? <div className="pFoot_t">
          <div className="pFoot_t_text">现在申请,立得30天免费试用机会!</div>
          <div className="pFoot_t_btn btn" onClick={ () => { this.showPop() } }>立即试用</div>
          </div> : <div className="pFoot_t pFoot_cl_t">
            招商咨询：18321375963
          </div>
        }
        
        <div className="pFoot_b_box">
          <div className="pFoot_b">
            <div className="pFoot_b_item">
              <div className="pFoot_b_title">Contact us</div>
              <div className="pFoot_b_item_text">客服热线</div>
              <div className="pFoot_b_item_mobile">18321375963</div>
              <div className="pFoot_b_item_text">邮箱：zxh@baguatan.cn</div>
              <div className="pFoot_b_item_text"><span>地址：</span>浙江省杭州市滨江区江虹南路316号京安创<br />业园3号楼605室</div>
            </div>
            <div className="pFoot_b_item">
              <div className="pFoot_b_title">Product</div>
              <ul onClick={() => { this.props.goPage('/park/product') }}>
                <li>平台管理系统</li>
                <li>生态小程序</li>
                <li>一体机</li>
              </ul>
            </div>
            <div className="pFoot_b_item">
              <div className="pFoot_b_title">Cases</div>
              <ul onClick={ () => { this.props.goPage('/park/example') } }>
                <li>杭州</li>
                <li>台州</li>
                <li>丽水</li>
                <li>常州</li>
                <li>其他</li>
              </ul>
            </div>
            <div className="pFoot_b_item">
              <div className="pFoot_b_title">About us</div>
              <ul onClick={() => { this.props.goPage('/park/about') }}>
                <li>公司介绍</li>
                <li>发展历程</li>
              </ul>
            </div>
            <div className="pFoot_b_item pFoot_b_item_qr">
              <div className="pFoot_b_title">Attention us</div>
              <img src={require('../../assets/img/qrcode.jpg')} alt="" />
              <p>智慧园区</p>
            </div>
          </div>
          
        </div>
        <div className="copyRight">
          <div>版权所有: 杭州搜雪网络有限公司</div>
          <a href="https://beian.miit.gov.cn">浙ICP备15041734号-1</a>
        </div>
        
      </div>			
		)
	}
}

export default Foot