import React, { Component } from 'react';
import '../assets/css/product.scss'
import Swiper from 'swiper/js/swiper.js'


import Banner from './compent/banner'
import Company from './compent/company'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bannerList: [
        {
          title: '园区管理开放平台系统',
          text: '园区运营管理与营收增长的神器',
          img: require("../assets/img/product/product_banner1.png"),
          // btncolor: '#00B571',
          color: '#fff'
        },  {
          title: '园区生态小程序',
          text: '大同园区 人，物，场，生态全链路',
          img: require("../assets/img/product/product_banner2.png"),
          // btncolor: '#061829',
          color: '#fff'
        }, {
          title: '开放平台集成所有园区应用',
          text: ['整合全国各大园区运营与管理经验', '开放平台，集成全部园区应用系统'],
          img: require("../assets/img/product/product_banner3.png"),
          // btncolor: '#FF8702',
          color: '#fff'
        }
      ]
    }
  }
  componentDidMount() {
    new Swiper ('.swiper-container', {
      loop: true,  //循环
      observer: true,
      autoplay: {   //滑动后继续播放（不写官方默认暂停）
        disableOnInteraction: false,
        delay: 5000
      },
      pagination: {  //分页器
          el: '.swiper-pagination'
      }
    })
  }
	render() {
		return(
      <div className="pProduct">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {
              this.state.bannerList.map((item, index) => {
                return (
                  <div className="swiper-slide" key={ index }>
                    <Banner
                      title={item.title}
                      text={item.text}
                      img={item.img}
                      btncolor={item.btncolor}
                      color={item.color}
                    />
                  </div>
                )
              })
            }
            
          </div>
          <div className="swiper-pagination"></div>
      </div>
        
        
        <div className="pProduct_title actDom" data-actcls="fadeInUp">园区管理开放平台系统
        <div className="title_subtitle">increment</div>
        </div>

        <div className="pProduct_item">
          <img className="pProduct_item_r actDom" data-actcls="bounceInLeft" src="" data-src={require('../assets/img/product/product_img1.png')} alt="" />
          
          <div className="pProduct_item_l actDom" data-actcls="bounceInRight">
            <h6>上千款园区管理应用可供选择</h6>
            <img src={ require('../assets/img/product/product_icon1.png') } alt=""/>
            <div className="pProduct_item_l_subt">后台</div>
            <i></i>
            <p>解决园区多套系统操作繁琐数据孤立的痛点</p>
            <p>系统功能覆盖园区运营，资产管理及各种办公及生活场景</p>
          </div>
        </div>



        

        <div className="pProduct_itembox">
          <div className="pProduct_title actDom" data-actcls="fadeInUp">生态小程序</div>
          <div className="pProduct_item">
            
            <div className="pProduct_item_l actDom" data-actcls="bounceInLeft">
              <h6>解决园区生态建设难题</h6>
              <img src={ require('../assets/img/product/product_icon2.png') } alt=""/>
              <div className="pProduct_item_l_subt">后台</div>
              <i></i>
              <p>解决园区营收单一和移动化管理的痛点</p>
              <p>打通园区各资源位的盈利能力，助力园区营业额增长</p>
            </div>

            <img className="pProduct_item_r actDom" data-actcls="bounceInRight" src="" data-src={require('../assets/img/product/product_img2.png')} alt="" />
            
          </div>
        </div>
        

        

        <div className="pProduct_title actDom" data-actcls="fadeInUp">智能硬件
        <div className="title_subtitle">machine</div>
        </div>

        <div className="pProduct_item">
          <img className="pProduct_item_r actDom" data-actcls="bounceInLeft" src="" data-src={require('../assets/img/product/product_img3.png')} alt="" />
          
          <div className="pProduct_item_l actDom" data-actcls="bounceInRight">
            <h6>与各大银行建立战略合作助力企业普惠金融</h6>
            <img src={ require('../assets/img/product/product_icon3.png') } alt=""/>
            <div className="pProduct_item_l_subt">后台</div>
            <i></i>
            <p>解决中小企业发展融资难题</p>
            <p>系统功能覆盖园区各种办公及生活场景,园区内各角色衣食住行</p>
          </div>
        </div>

        <div className="pProduct_title actDom" data-actcls="fadeInUp">合作伙伴
        <div className="title_subtitle">Partner</div>
        </div>

        <Company />
      </div>				
		)
	}
}

export default Home