import React, { Component } from 'react';
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { createHashHistory } from 'history';
import store from './store';

import Head from './pages/compent/head'
import Pop from './pages/compent/pop'
import Foot from './pages/compent/foot'
import Home from './pages/home'
import Channel from './pages/channel'
// import Supplier from './pages/supplier'
import Product from './pages/product'
import Example from './pages/example'
import News from './pages/news'
import NewsDetail from './pages/news/newsDetail'
import About from './pages/about'




const history = createHashHistory();



class App extends Component {
  constructor(props) {
    super(props)  
    // console.log(store.getState())
    this.state = {
      nowPage: history.location.pathname === '/' ? '/park' : history.location.pathname,
			robotShow: false,
      ...store.getState()
    }
    // 注意需要绑定 this
    this.handleStoreChange = this.handleStoreChange.bind(this);
    // 注册监听store，store变化后调用组件的handleStoreChange方法更新组件的state
    store.subscribe(this.handleStoreChange); 
  }
  handleStoreChange() {
    // console.log(store.getState())
    this.setState(
      store.getState()
    )
  }
  goPage(path) {
    if (path !== this.state.nowPage) {
      history.push(path)
    }
  }
  componentDidMount() {
    history.listen(() => {
      if (history.location.pathname !== this.state.nowPage) {
        this.setState({
          nowPage: history.location.pathname
        })
        // if (!history.location.pathname.match('/park/news')) {
          
          window.scrollTo(0, 1)
          window.scrollTo(0, 0)
        // }
        
      }
    })
		window.addEventListener('scroll', (e) => {
			let wHeight = document.body.scrollTop || document.documentElement.scrollTop
			if(!this.state.robotShow && wHeight + window.innerHeight > 1100) {
				this.setState({
					robotShow: true
				})
			} else if(this.state.robotShow && wHeight + window.innerHeight < 1100) {
				this.setState({
					robotShow: false
				})
			}
		})
  }
  cancelDialog() {
    store.dispatch({
      type: 'toggleDialog',
      value: false
    })
  }
	gobgt() {
		window.location.href = "https://www.baguatan.cn/"
	}
  // showToast() {
  //   store.dispatch({
  //     type: 'showToast',
  //     value: '我擦'
  //   })
  //   setTimeout(() => {
  //     store.dispatch({type: 'hideToast'})
  //   }, 2000);
  // }
    // <Route path="/park/supplier" component={ Supplier } />
  render() {
    return (
      <div className="appBox">
        {/* <div onClick={ () => { this.showToast() } }>123</div> */}
				{
					this.state.robotShow ? <img src={ require('./assets/img/robot.png') } className="robot animated heartBeat" alt="" onClick={() => { this.gobgt() }} /> : ''
				}
				
        <Head goPage={ (path) => { this.goPage(path) } } nowPage={ this.state.nowPage } />
        <Router history={history}>
        
          <Switch>
            
            <Route exact path="/park" component={ Home } />
            <Route path="/park/channel" component={ Channel } />
          
            <Route path="/park/product" component={ Product } />
            <Route path="/park/example" component={ Example } />
            <Route path="/park/news/:type/:page" component={ News } />
            <Route path="/park/newsDetail/:id" component={ NewsDetail } />
            <Route path="/park/about" component={ About } />
            
            <Redirect from="/" to="/park"></Redirect>
          </Switch>
        </Router>
        <Foot goPage={ (path) => { this.goPage(path) } }/>
        {this.state.inputPopOff ? <Pop /> : ''}
        {
          this.state.pToastOff ? <div className="pToast">
            <span>{ this.state.pToastTitle }</span>
        </div> : ''
        }
        
        {
          this.state.pDialogOff ? <div className="dialogPop">
          <div className="dialogPop_con">
            <div className="dialogPop_cancel" onClick={ () => { this.cancelDialog() } }></div>
            <div className="dialogPop_title">提示</div>
              <div className="dialogPop_text">{ this.state.dialogText }</div>
              {/* 您的信息已提交，我们有专门的客服联系您 */}
            <div className="dialogPop_btn">
              <div onClick={ () => { this.cancelDialog() } }>确定</div>
            </div>
          </div>
        </div> : ''
        }
        
        
      </div>
    )
  }
}
// function App() {
//   return (
//     <div className="App">
      
//     </div>
//   );
// }



export default App;
