import { createStore } from 'redux';




let reducer = (state = {
  inputPopOff: false,
  pToastOff: false,
  pToastTitle: '',
  pDialogOff: false,
  dialogText: '',
  adminUrl: 'https://souxue.cc/ryyadmin/login/login.html'
  // pToastCls: false
}, action) => {
  if (action.type) {
     // why copy old state -> newState ? reducer 可以接收state 不能修改state！！！
    const newState = JSON.parse(JSON.stringify(state)); // 深度拷贝
    
    // 免费体验弹窗
    if(action.type === 'changePop_off'){
      newState.inputPopOff = action.value;
    }
    
    // 提示框
    else if (action.type === 'showToast') {
      newState.pToastOff = true;
      newState.pToastTitle = action.value || '提示'
      // setTimeout(() => {
      //    newState.pToastOff = false;
      // }, 1000);
    } else if (action.type === 'hideToast') {
      newState.pToastOff = false;
    }
    
    else if (action.type === 'toggleDialog') {
      // console.log(action)
      newState.pDialogOff = action.value;
      newState.dialogText = action.text || '提交成功';
    }
    // else if (action.type === 'hideDialog') {
    //   newState.pDialogOff = false;
    // }
    return newState;
  }
  
  return state;
}


const store = createStore(reducer);


// console.log(store)



export default store;