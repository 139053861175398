import React, { Component } from 'react';
import '../assets/css/home.scss'
import store from '../store';
import Swiper from 'swiper/js/swiper.js'


import Banner from './compent/banner'
import Company from './compent/company'

class Home extends Component {
  constructor(props) {
    super(props)
    let dotmes = []
    for (let i = 0; i < 43; i++) {
      if (i === 0) {
        dotmes.push({ title: '河南', val: 2 })
      } else if (i === 1) {
        dotmes.push({ title: '丽水', val: 8 })
      } else if (i === 2) {
        dotmes.push({ title: '衢州', val: 4 })
      } else if (i === 3) {
        dotmes.push({ title: '江苏', val: 2 })
      } else if (i === 4) {
        dotmes.push({ title: '宁波', val: 3 })
      } else if (i === 5) {
        dotmes.push({ title: '嘉兴', val: 8 })
      } else if (i === 6) {
        dotmes.push({ title: '杭州', val: 20 })
      } else if (i === 7) {
        dotmes.push({ title: '温州', val: 7 })
      } else {
        dotmes.push({})
      }
      
    }
    this.state = {
      // 园区营收生态智慧化解决方案
      way1List: [
        {
          type: 'title',
          con: '易  用'
        }, {
          type: 'text',
          con: '十分钟安装自己的操作系统，生成园区自己的小程序, 基础模块快速上手'
        }, {
          type: 'text',
          con: '开放API接口，迅速打通其他软件系统产品'
        }, {
          type: 'title',
          con: '管理方便：'
        }, {
          type: 'text',
          con: '改变传统系统多而杂，信息孤立的管理模式'
        }, {
          type: 'text',
          con: '数据统一，便于数据的统一抓取，归集；'
        }, {
          type: 'text',
          con: '流程化管理，让园区内办事更加快速'
        }, {
          type: 'title',
          con: '低成本：'
        }, {
          type: 'text',
          con: '每个功能就是一个应用模块，一键下载，不用就删除'
        }, {
          type: 'text',
          con: '传统软件系统需要几十万的，现在几百几千元就能搞定！'
        }, {
          type: 'title',
          con: '低成本：'
        }, {
          type: 'text',
          con: ' 集成了很多的赚钱应用，电商系统，商机系统，金融系统，广告管理系统……'
        }
      ],
      // 园区内企业资本解决方案
      way2List: [
        {
          type: 'title',
          con: '沟通更便捷：'
        }, {
          type: 'text',
          con: '园区自己的小程序解决了与企业90%的办事与沟通，还有沟通反馈等功能。'
        }, {
          type: 'title',
          con: '为企业带来更多价值'
        }, {
          type: 'text',
          con: '企业跟园区的互动更加方便，提高效率，降低沟通成本'
        }, {
          type: 'title',
          con: '效率带来集约化：'
        }, {
          type: 'text',
          con: '接入更多的服务，使第三方服务接入园区，应用化，信息化，流程化。'
        }
      ],
      // 全场景赋能园区智能化升级
      updateList: [
        {
          img: require('../assets/img/home/home_update_icon1.png'),
          title: '员工 · 园区 · 企业'
        }, {
          img: require('../assets/img/home/home_update_icon2.png'),
          title: 'AI智能硬件 · AI智能系统'
        }, {
          img: require('../assets/img/home/home_update_icon3.png'),
          title: '开放平台 · 营收倍增 · 资产管理'
        }
      ],
      // 360度°全方位一站式服务
      serverList: [
        {
          img: require('../assets/img/home/home_server_icon1.png'),
          title: '专业客服答疑',
          text: '7*24小时专业客服指导'
        }, {
          img: require('../assets/img/home/home_server_icon2.png'),
          title: '私有定制化服务',
          text: '提供1对1定制化服务'
        }, {
          img: require('../assets/img/home/home_server_icon3.png'),
          title: '海量应用一键使用',
          text: '开放平台提供海量应用免费使用'
        }, {
          img: require('../assets/img/home/home_server_icon4.png'),
          title: '线上视频教学',
          text: '遇到操作问题，视频连线1对1教学'
        }
      ],
      achieveList: [
        {
          img: require('../assets/img/home/home_achieve_icon1.png'),
          bg: require('../assets/img/home/pic-q@2x.png'),
          title: '大狗创工厂',
          text: '我们也试过自己找团队开发,结果反复沟通还是做不出来我们想要的方案.后来决定用YUANOS增值系统,专业的事交给专业的人,做园区还得用YUANOS。'
        }, {
          img: require('../assets/img/home/home_achieve_icon2.png'),
          bg: require('../assets/img/home/pic-w@2x.png'),
          title: '为美',
          text: 'YUANOS平台系统帮我们解决了大问题，从前园区运营需要几十套系统，操作繁琐，系统之间数据孤立。现在只用一套YUANOS就够了，它功能强大，可以根据自己需求选择系统应用。应用多，还免费，为我们每年节省了几十万的系统使用费。'
        }, {
          img: require('../assets/img/home/home_achieve_icon3.png'),
          bg: require('../assets/img/home/pic-r@2x.png'),
          title: '点睛之梦',
          text: '我们园区的运营和架构模式与多数园区模式有很多不同。我们调研了很多系统都不能满足对系统的需求。最后发现了YUANOS系统，终于找到适合我们的系统。园区管理就用YUNAOS。'
        }, {
          img: require('../assets/img/home/home_achieve_icon4.png'),
          bg: require('../assets/img/home/pic-e@2x.png'),
          title: 'isuor',
          text: '今年我们领导给出的营收目标比去年提高了20%，压力更大。我们找到YUANOS平台，他们帮我们出了一套营收提升的互联网化方案。今年用上这套方案后，园区多了好几条赚钱渠道，目标也完成了，还超了6%，领导对我的工作提出表扬。'
        }, {
          img: require('../assets/img/home/home_achieve_icon5.png'),
          bg: require('../assets/img/home/pic-5@2x.png'),
          title: '灿煜网络',
          text: 'YUANOS强大的运营管理系统帮助我们解放了劳动力，提高了工作效率，本来3个人的工作现在只用1个人就可以完成了，大大节约了人工成本。用他们的增值服务还能给园区带来收益。'
        }, 
      ],
      dotmes,
      initmes: []
    }
  }
  
  showPop() {
    store.dispatch({
      type: 'changePop_off',
      value: true
    })
  }
  showToast() {
    console.log(this.props)
  }
  goPage(url) {
    this.props.history.push({
      pathname: url,
      // search: '?sort=name',
      // query: { fromquery: 123 },
      // state: { fromstate: 123 }
    })
  }
  slide(dir) {
    if (dir === 'left') {
      this.useswiper.slidePrev()
    } else {
      this.useswiper.slideNext()
    }
  }
  componentDidMount() {
    // window.addEventListener('scroll', this.scrollFun.bind(this))
    window.onscroll = this.scrollFun.bind(this)
    this.useswiper = new Swiper('.swiper-container', {
      slidesPerView: 4,
      spaceBetween: 30,
      loop: true,
    });
  }
  componentWillUnmount() {
    this.timer && clearInterval(this.timer)
    window.onscroll = null
  }
  scrollFun() {
    let wHeight = document.body.scrollTop || document.documentElement.scrollTop
    let h = document.querySelector('.pHome_map').offsetTop
    if (wHeight + window.innerHeight > h) {
      window.onscroll = null
      setTimeout(() => {
        this.timer = setInterval(() => {
          this.becomeMore()
        }, 50);
      }, 800);
      
    }
  }
  becomeMore() {
    let initmes = this.state.initmes
    let off = false
    for (let i in this.state.dotmes) {
      if (this.state.dotmes[i].val && this.state.dotmes[i].val >( initmes[i] || 0)) {
        initmes[i] = initmes[i] ? initmes[i] + 1 : 1
        off = true
      }
    }
    
    if (off) {
      this.setState({
        initmes
      })
    } else {
      this.timer && clearInterval(this.timer)
    }
  }
	render() {
    return (
      <div className="pHome">
        <Banner color="#fff" titleImg={require("../assets/img/textLogo.png")} title="YUANOS" text={['这一次，改变园区的智慧化管理', '让园区有了自己 的“操作系统”', '底层的区块链技术，让数据更加安全可靠！']} img={require("../assets/img/home/banner@2x.png")} />
        

        <div className="pPhome_opera">
          <div className="pPhome_opera_lbg"></div>
          <img className="pPhome_opera_rbg" src={ require('../assets/img/home/subbanner.png') } alt=""/>
          <div className="pPhome_opera_main actDom" data-actcls="bounceInLeft">
            {/* <div className="pHome_way_main actDom" data-actcls="bounceInLeft"> */}
            <div className="pPhome_opera_title">给园区业主带来什么</div>
            <div className="pPhome_opera_mes">
              {
                this.state.way1List.map((item, index) => {
                  return (
                    <div key={ index } className={ `pHome_way_${item.type}` }>{ item.con }</div>
                  )
                })
              }
              <div className="pHome_way_btn">
                <div className="btn" onClick={ () => { this.showPop() } }>免费体验</div>
                <div className="btn" onClick={ () => { this.goPage('/park/product') } }>功能介绍</div>
              </div>
            </div>
              
            {/* </div> */}
          </div>
        </div>
        
        <div className="pHome_title pHome_rz_title actDom" data-actcls="fadeInUp">为企业带来什么</div>

        <div className="pHome_rz">
          <div className="pHome_bgtext">High efficiency</div>
          
          <div className="pHome_rz_main">
            <div className="pHome_rz_l actDom" data-actcls="bounceInLeft">
              <img src={ require('../assets/img/home/picture-t@2x.png') } alt=""/>
            </div>
            <div className="pHome_rz_r actDom" data-actcls="bounceInRight">
              {/* <div className="pHome_way_main actDom" data-actcls="bounceInRight"> */}
                {
                  this.state.way2List.map((item, index) => {
                    return (
                      <div key={ index } className={ `pHome_way_${item.type}` }>{ item.con }</div>
                    )
                  })
                }
                <div className="pHome_way_btn">
                  <div className="btn" onClick={ () => { this.showPop() } }>免费体验</div>
                  <div className="btn" onClick={ () => { this.goPage('/park/product') } }>功能介绍</div>
                </div>
              {/* </div> */}
            </div>
          </div>
        </div>

        
          {/* <div className="pHome_way">
            <div className="pHome_way_main actDom" data-actcls="bounceInLeft">
              {
                this.state.way1List.map((item, index) => {
                  return (
                    <div key={ index } className={ `pHome_way_${item.type}` }>{ item.con }</div>
                  )
                })
              }
              <div className="pHome_way_btn">
                <div className="btn" onClick={ () => { this.showPop() } }>免费体验</div>
                <div className="btn" onClick={ () => { this.goPage('/park/product') } }>功能介绍</div>
              </div>
            </div>
            <img src="" data-src={ require('../assets/img/home/home_mianpic1.png') } className="pHome_way_pic actDom" data-actcls="bounceInRight" alt=""/>
          </div> */}

          {/* <div className="pHome_title actDom" data-actcls="fadeInUp">更高效，贴心地服务入驻企业</div>

          <div className="pHome_way">
          <img src="" data-src={ require('../assets/img/home/home_mianpic2.png') } className="pHome_way_pic actDom" data-actcls="bounceInLeft" alt=""/>
            <div className="pHome_way_main actDom" data-actcls="bounceInRight">
              {
                this.state.way2List.map((item, index) => {
                  return (
                    <div key={ index } className={ `pHome_way_${item.type}` }>{ item.con }</div>
                  )
                })
              }
              <div className="pHome_way_btn">
                <div className="btn" onClick={ () => { this.showPop() } }>免费体验</div>
                <div className="btn" onClick={ () => { this.goPage('/park/product') } }>功能介绍</div>
              </div>
            </div>
          </div> */}

        <div className="pHome_main">
          <div className="pHome_title actDom" data-actcls="fadeInUp">物联网赋能园区智能化升级
            <div className="title_subtitle">Intelligence</div>
          </div>

          <div className="pHome_update actDom" data-actcls="fadeInUp" data-lazypic="1">
            {
              this.state.updateList.map((item, index) => {
                return (
                  <div key={ index } className="pHome_update_item">
                    <img src='' data-src={ item.img } alt="" />
                    <h6>{ item.title }</h6>
                  </div>
                )
              })
            }
          </div>

        </div>
          
        
        <div className="pHome_serverbox">
          <div className="pHome_title actDom" data-actcls="fadeInUp">全方位一站式服务</div>
       
          {/* <div className="pHome_bgtext">One stop service</div> */}
          
          <div className="pHome_server actDom" data-actcls="fadeInUp" data-lazypic="1">
            {
              this.state.serverList.map((item, index) => {
                return (
                  <div key={ index } className="pHome_server_item">
                    <img src="" data-src={ item.img } alt="" />
                    <h6>{ item.title }</h6>
                    <p>{ item.text }</p>
                  </div>
                )
              })
            }
          </div>
        </div>

          


        <div className="pHome_main">
          <div className="pHome_title actDom" data-actcls="fadeInUp">他们的使用心得
          <div className="title_subtitle">Experience</div>
          </div>

          <div className="pHome_slide actDom" data-actcls="fadeInUp" data-lazypic="1">
          <div className="swiper-container">
            {/* <div className="aa swiper-wrapper"> */}
              <div className="swiper-wrapper">
                {
                  this.state.achieveList.map((item, index) => {
                    return (
                      <div className="swiper-slide pHome_achieve_item" key={ index }>
                        {/* <img src='' data-src={ item.img } alt="" />
                        <h6>{ item.title }</h6>
                        <p>{ item.text }</p> */}
                        <img src='' data-src={ item.bg } alt="" className="pHome_achieve_bg" />
                        <div className="pHome_achieve_main">
                          <div>{item.title}</div>
                          <p>{item.text}</p>
                          <img src='' data-src={ item.img } alt="" />
                        </div>
                        <div className="pHome_achieve_des">
                          <img src='' data-src={item.img} alt="" />
                          {item.title}
                        </div>
                      </div>
                    )
                    
                  })
                }
              </div>
            {/* </div> */}
            
          </div>
          <img src={ require("../assets/img/example/left.png") } alt="" className="pHome_slide_left btn" onClick={ () => { this.slide('left') } } />
          <img src={require("../assets/img/example/left.png")} alt="" className="pHome_slide_right btn"  onClick={ () => { this.slide('right') } } />
        </div>

          {/* <div className="pHome_achieve actDom" data-actcls="fadeInUp" data-lazypic="1">
            {
              this.state.achieveList.map((item, index) => {
                return (
                  <div key={ index } className="pHome_achieve_item">
                    <img src='' data-src={ item.img } alt="" />
                    <h6>{ item.title }</h6>
                    <p>{ item.text }</p>
                  </div>
                )
              })
            }
          </div> */}

          


        </div>




        <div className="pHome_title actDom" data-actcls="fadeInUp">未来，将会有10000+园区/办公楼使用YUANOS
          <div className="title_subtitle">Future</div>
        </div>
				
        <div className="pHome_map">
					<div className="pHome_map_com">
            <img src={require("../assets/img/home/map_simple.png")} alt="" />
            {/* <img className="actDom" data-actcls="fadeInUp" src="" data-src={require("../assets/img/home/map_simple.png")} alt="" /> */}
            <ul>
              {/* <li className="pHome_map_hn">
                <div><i></i></div>
                <p>1000</p>
              </li> */}
              {
                this.state.dotmes.map((item, index) => {
                  return (
                    <li key={index}>
                      <div><i></i></div>
                      {
                        this.state.initmes[index] ? <p>{ this.state.initmes[index] || '' }</p> : ''
                      }
                     <span>{ item.title }</span>
                    </li>
                  )
                })
              }
            </ul>
          </div>	
          
          <p className="pHome_map_mes">
            我们整合了行业内最优质的资源,加上对技术的把控,让园区运营管<br />理变的便捷、易用、好用
          </p>
        </div>
       
        <div className="pHome_title actDom" data-actcls="fadeInUp">合作伙伴
          <div className="title_subtitle">Partner</div>
        </div>
        <Company />
      </div>
		)
	}
}

export default Home