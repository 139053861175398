import React, { Component } from 'react'
import { _getNewsDl } from '../../assets/js/request.js'


class News extends Component {
  constructor(props) {
    super(props)
    this.state = {
      info: {}
    }
  }
  componentDidMount() {
    this.getinfo()
  }
  getinfo() {
    _getNewsDl({
      data: {
        id: this.props.match.params.id
      }
    }).then(res => {
      this.setState({
        info: {
          ...res,
          content: res.content.replace(/<img/g, '<img style="max-width: 100%" ')
        }
      })
    })
  }
  render() {
    return (
      <div className="newsdl">
        <h6 className="newsdl_title">
          { this.state.info.title }
        </h6>
        <div className="newsdl_time">发布时间: { this.state.info.create_time }</div>
        <div className="newsdl_con" dangerouslySetInnerHTML={{
              __html: this.state.info.content
            }}/>
      </div>
    )
  }
}
export default News