import React, { Component } from 'react';
// import '../../assets/css/compent/together.scss'

class Company extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comList: [
        require('../../assets/img/company/company_pic1.png'),
        require('../../assets/img/company/company_pic2.png'),
        require('../../assets/img/company/company_pic3.png'),
        require('../../assets/img/company/company_pic4.png'),
        require('../../assets/img/company/company_pic5.png'),
        require('../../assets/img/company/company_pic6.png'),
        require('../../assets/img/company/company_pic7.png'),
        require('../../assets/img/company/company_pic8.png'),
        require('../../assets/img/company/company_pic9.png'),
        require('../../assets/img/company/company_pic10.png'),
        require('../../assets/img/company/company_pic11.png'),
        require('../../assets/img/company/company_pic12.png'),
        require('../../assets/img/company/company_pic13.png'),
        require('../../assets/img/company/company_pic14.png'),
        require('../../assets/img/company/company_pic15.png')
      ]
    }
  }
	render() {
		return(
      <div className="pCompany actDom" data-lazypic="1" data-actcls="fadeInUp">
        {
          this.state.comList.map((item, index) => {
            return (
              <img key={ index } src="" data-src={ item } alt=""/>
            )
           
          })
        }
      </div>			
		)
	}
}

export default Company