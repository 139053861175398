import React, { Component } from 'react';
// import '../../assets/css/compent/together.scss'
import store from '../../store'
import { _subFree, _getcode, _checkPhone } from '../../assets/js/request'

class Head extends Component {
  constructor(props) {
    super(props)
    this.state = {
      provinceList: ["北京", "广东", "上海", "天津", "重庆", "辽宁", "江苏", "湖北", "四川", "陕西", "河北", "山西", "河南", "吉林", "黑龙江", "内蒙古", "山东", "安徽", "浙江", "福建", "湖南", "广西", "江西", "贵州", "云南", "西藏", "海南", "甘肃", "宁夏", "青海", "新疆", "香港", "澳门", "台湾"],
      allList: {
        '北京': ['北京'],
        '广东': ['广州', '深圳', '珠海', '汕头', '韶关', '佛山', '江门', '湛江', '茂名', '肇庆', '惠州', '梅州', '汕尾', '河源', '阳江', '清远', '东莞', '中山', '潮州', '揭阳', '云浮'],
        '上海': ['上海'],
        '天津': ['天津'],
        '重庆': ['重庆'],
        '辽宁': ['沈阳', '大连', '鞍山', '抚顺', '本溪', '丹东', '锦州', '营口', '阜新', '辽阳', '盘锦', '铁岭', '朝阳', '葫芦岛'],
        '江苏': ['南京', '苏州', '无锡', '常州', '镇江', '南通', '泰州', '扬州', '盐城', '连云港', '徐州', '淮安', '宿迁'],
        '湖北': ['武汉', '黄石', '十堰', '荆州', '宜昌', '襄樊', '鄂州', '荆门', '孝感', '黄冈', '咸宁', '随州', '恩施土家族苗族自治州', '仙桃', '天门', '潜江', '神农架林区'],
        '四川': ['成都', '自贡', '攀枝花', '泸州', '德阳', '绵阳', '广元', '遂宁', '内江', '乐山', '南充', '眉山', '宜宾', '广安', '达州', '雅安', '巴中', '资阳', '阿坝藏族羌族自治州', '甘孜藏族自治州', '凉山彝族自治州'],
        '陕西': ['西安', '铜川', '宝鸡', '咸阳', '渭南', '延安', '汉中', '榆林', '安康', '商洛'],
        '河北': ['石家庄', '唐山', '秦皇岛', '邯郸', '邢台', '保定', '张家口', '承德', '沧州', '廊坊', '衡水'],
        '山西': ['太原', '大同', '阳泉', '长治', '晋城', '朔州', '晋中', '运城', '忻州', '临汾', '吕梁'],
        '河南': ['郑州', '开封', '洛阳', '平顶山', '安阳', '鹤壁', '新乡', '焦作', '濮阳', '许昌', '漯河', '三门峡', '南阳', '商丘', '信阳', '周口', '驻马店'],
        '吉林': ['长春', '吉林', '四平', '辽源', '通化', '白山', '松原', '白城', '延边朝鲜族自治州'],
        '黑龙江': ['哈尔滨', '齐齐哈尔', '鹤岗', '双鸭山', '鸡西', '大庆', '伊春', '牡丹江', '佳木斯', '七台河', '黑河', '绥化', '大兴安岭地区'],
        '内蒙古': ['呼和浩特', '包头', '乌海', '赤峰', '通辽', '鄂尔多斯', '呼伦贝尔', '巴彦淖尔', '乌兰察布', '锡林郭勒盟', '兴安盟', '阿拉善盟'],
        '山东': ['济南', '青岛', '淄博', '枣庄', '东营', '烟台', '潍坊', '济宁', '泰安', '威海', '日照', '莱芜', '临沂', '德州', '聊城', '滨州', '菏泽'],
        '安徽': ['合肥', '芜湖', '蚌埠', '淮南', '马鞍山', '淮北', '铜陵', '安庆', '黄山', '滁州', '阜阳', '宿州', '巢湖', '六安', '亳州', '池州', '宣城'],
        '浙江': ['杭州', '宁波', '温州', '嘉兴', '湖州', '绍兴', '金华', '衢州', '舟山', '台州', '丽水'],
        '福建': ['福州', '厦门', '莆田', '三明', '泉州', '漳州', '南平', '龙岩', '宁德'],
        '湖南': ['长沙', '株洲', '湘潭', '衡阳', '邵阳', '岳阳', '常德', '张家界', '益阳', '郴州', '永州', '怀化', '娄底', '湘西土家族苗族自治州'],
        '广西': ['南宁', '柳州', '桂林', '梧州', '北海', '防城港', '钦州', '贵港', '玉林', '百色', '贺州', '河池', '来宾', '崇左'],
        '江西': ['南昌', '景德镇', '萍乡', '九江', '新余', '鹰潭', '赣州', '吉安', '宜春', '抚州', '上饶'],
        '贵州': ['贵阳', '六盘水', '遵义', '安顺', '铜仁地区', '毕节地区', '黔西南布依族苗族自治州', '黔东南苗族侗族自治州', '黔南布依族苗族自治州'],
        '云南': ['昆明', '曲靖', '玉溪', '保山', '昭通', '丽江', '普洱', '临沧', '德宏傣族景颇族自治州', '怒江傈僳族自治州', '迪庆藏族自治州', '大理白族自治州', '楚雄彝族自治州', '红河哈尼族彝族自治州', '文山壮族苗族自治州', '西双版纳傣族自治州'],
        '西藏': ['拉萨', '那曲地区', '昌都地区', '林芝地区', '山南地区', '日喀则地区', '阿里地区'],
        '海南': ['海口', '三亚', '五指山', '琼海', '儋州', '文昌', '万宁', '东方', '澄迈县', '定安县', '屯昌县', '临高县', '白沙黎族自治县', '昌江黎族自治县', '乐东黎族自治县', '陵水黎族自治县', '保亭黎族苗族自治县', '琼中黎族苗族自治县'],
        '甘肃': ['兰州', '嘉峪关', '金昌', '白银', '天水', '武威', '酒泉', '张掖', '庆阳', '平凉', '定西', '陇南', '临夏回族自治州', '甘南藏族自治州'],
        '宁夏': ['银川', '石嘴山', '吴忠', '固原', '中卫'],
        '青海': ['西宁', '海东地区', '海北藏族自治州', '海南藏族自治州', '黄南藏族自治州', '果洛藏族自治州', '玉树藏族自治州', '海西蒙古族藏族自治州'],
        '新疆': ['乌鲁木齐', '克拉玛依', '吐鲁番地区', '哈密地区', '和田地区', '阿克苏地区', '喀什地区', '克孜勒苏柯尔克孜自治州', '巴音郭楞蒙古自治州', '昌吉回族自治州', '博尔塔拉蒙古自治州', '石河子', '阿拉尔', '图木舒克', '五家渠', '伊犁哈萨克自治州'],
        '香港': ['香港'],
        '澳门': ['澳门'],
        '台湾': ['台北市', '高雄市', '台北县', '桃园县', '新竹县', '苗栗县', '台中县', '彰化县', '南投县', '云林县', '嘉义县', '台南县', '高雄县', '屏东县', '宜兰县', '花莲县', '台东县', '澎湖县', '基隆市', '新竹市', '台中市', '嘉义市', '台南市']
      },
      cityList: [],
      nowProvince: '',
      nowCity: '',
      codeAlert: '未填入正确的手机号',
      mobile: '',
      code: '',
      name: '',
      pDialogOff: false
    }
  }
  
  cancelDialog() {
    window.location.href = store.getState().adminUrl
    // this.setState({
    //   pDialogOff: false
    // })
  }
  cancelPop() {
    store.dispatch({
      type: 'changePop_off',
      value: false
    })
  }
  componentDidMount() {
    this.province = document.getElementById('province')
    this.city = document.getElementById('city')
    this.toggleBody(1)
  }
  // 取消滚动条
  toggleBody(isPin) {
    if (isPin) {
      document.body.style.height = '100vh'
      document.body.style['overflow-y'] = 'hidden'
    }
    else {
      document.body.style.height = 'unset'
      document.body.style['overflow-y'] = 'auto'
    }
  }
  componentWillUnmount() {
    this.toggleBody(0)
    this.codeTimer && clearInterval(this.codeTimer)
  }
  changeProvice() {
    this.city.value = ''
    this.setState({
      nowProvince: this.province.value,
      cityList: this.state.allList[this.province.value],
      nowCity: ''
    })
    
  }
  changeCity() {
    this.setState({
      nowCity: this.city.value
    })
  }
  // 确定提交
  sureSub() {
    
    // store.dispatch({
    //   type: 'toggleDialog',
    //   value: true,
    //   text: '感谢您的免费体验申请，稍后我们有客服与您联系'
    // })
    // this.showToast('提交成功')
    let check = this.check()
    if (check) {
      this.showToast(check)
    } else {
      _subFree({
        data: {
          province: this.state.nowProvince,
          city: this.state.nowCity,
          name: this.state.name,
          phone: this.state.mobile,
          yzm: this.state.code
        }
      }).then(res => {
        // this.cancelPop()
        this.setState({
          pDialogOff: true
        })
        this.codeTimer && clearInterval(this.codeTimer)
        // window.location.href = store.getState().adminUrl
        
      })
    }
    
  }
  check() {
    if (!this.state.nowProvince) {
      return '请选择省'
    } else if (!this.state.nowCity) {
      return '请选择市'
    } else if (!this.state.name) {
      return '请填写园区名'
    } else if (!this.state.mobile) {
      return '请填写手机号'
    } else if (!(/^1[3456789]\d{9}$/.test(this.state.mobile))) {
      return '请填写正确的手机号'
    } else if (!this.state.code) {
      return '请填写验证码'
    } else {
      return false
    }
  }
  showToast(value) {
    store.dispatch({
      type: 'showToast',
      value
    })
    setTimeout(() => {
      store.dispatch({type: 'hideToast'})
    }, 2000);
  }
  getcode() {
    if (this.state.codeAlert === '获取验证码') {
      if (!this.state.mobile) {
        this.showToast('请先输入手机号')
        return 
      } else if (!(/^1[3456789]\d{9}$/.test(this.state.mobile))) {
        this.showToast('请先输入正确的手机号')
        return
      }
      this.setState({
        codeAlert: '正在获取...'
      })
      _getcode({
        data: {
          phone: this.state.mobile
        }
        
      }).then(res => {
        this.getlastTime()
      }).catch(err => {
        this.checkPhone()
      })
      
    }
  }
  checkPhone(num = '') {
    // this.state.codeAlert.match('正在获取') || 
    if (!(this.state.codeAlert.match('重新获取')) || num > 0) {
      if ((/^1[3456789]\d{9}$/.test(this.state.mobile))) {
        _checkPhone({
          data: {
            phone: this.state.mobile,
            state: 3
          }
        }).then(res => {
            
            this.setState({
              codeAlert: '获取验证码'
            })
        }).catch(err => {
          if (err.match('已存在')) {
            this.setState({
              codeAlert: '该手机号已提交过'
            })
          } else {
            this.setState({
              codeAlert: '该手机号当前不可申请'
            })
          }
        })
      } else {
        this.setState({
          codeAlert: '未填入正确的手机号'
        })
      }
    }
    
  }
  getlastTime() {
    // 60s倒计时
    this.codeTimer && clearInterval(this.codeTimer)
    let s = 60
    this.setState({
      codeAlert: s + 's后可重新获取'
    })
    this.codeTimer = setInterval(() => {
      if (--s > 0) {
        this.setState({
          codeAlert: s + 's后可重新获取'
        })
      } else {
        this.checkPhone(1)
        // this.setState({
        //   codeAlert: '获取验证码'
        // })
        this.codeTimer && clearInterval(this.codeTimer)
      }
      
    }, 1000);
  }
  putMes(e, key) {
    this.setState({
      [key]: e.target.value
    }, () => {
      if (key === 'mobile') {
        this.checkPhone()
      }
    })
    
    
  }
 
	render() {
    return (
      <div className="pPop_box">
        <div className="pPop">
          {/* <img className="pPop_cancel" src={require('../../assets/img/cancel.png')} alt="" onClick={ () => { this.cancelPop() } } /> */}
          <div className="pPop_cancel1" onClick={ () => { this.cancelPop() } }></div>
          <div className="pPop_form">
            <div className="pPop_form_l">*</div>
            <select className={ this.state.nowProvince ? 'pPop_havePro' : '' } defaultValue="" id="province" onChange={ () => this.changeProvice() }>
              <option value="" disabled hidden>请选择省</option>
              {
                this.state.provinceList.map((item, index) => {
                  return (
                    <option value={item} key={index}>{item}</option>
                  )   
                })
              }
            </select>
            <select id="city" className={ this.state.nowProvince ? '' : 'pPop_haveCity' } style={{ color: this.state.nowCity ? '#061829' : '#666666' }} defaultValue="" onChange={ () => this.changeCity() }>
              <option value="" disabled hidden>请选择市</option>
              {
                this.state.cityList.map((item, index) => {
                  return (
                    <option value={item} key={index}>{item}</option>
                  )   
                })
              }
            </select>
          </div>
          <div className="pPop_form">
            <div className="pPop_form_l">*</div>
            <input type="text" onChange={ (event) => { this.putMes(event, 'name') } } placeholder="园区名称"/>
          </div>
          <div className="pPop_form">
            <div className="pPop_form_l">*</div>
            <input type="number" onChange={ (event) => { this.putMes(event, 'mobile') } } placeholder="手机号"/>
          </div>
          <div className="pPop_form">
            <div className="pPop_form_l">*</div>
            <input type="text" onChange={ (event) => { this.putMes(event, 'code') } } placeholder="验证码" />
            <div className="pPop_form_code" style={{ background: this.state.codeAlert === '获取验证码' ? '#0274FF' : '#ccc' }} onClick={() => { this.getcode() }}>{ this.state.codeAlert }</div>
          </div>
          

          <div className="pPop_sub" onClick={ (() => {this.sureSub()}) }>免费体验</div>
          <p className="pPop_prompt">
            请填写真实信息,方便给您安排测试账号哦!<br />
            您也可以拨打18321375963咨询
          </p>
        </div>
        {
          this.state.pDialogOff ? <div className="dialogPop">
          <div className="dialogPop_con">
            <div className="dialogPop_cancel" onClick={ () => { this.cancelDialog() } }></div>
            <div className="dialogPop_title">提示</div>
              <div className="dialogPop_text">您的体验账号是{this.state.mobile}<br />密码是{ this.state.mobile }</div>
            <div className="dialogPop_btn">
              <div onClick={ () => { this.cancelDialog() } }>前往登录</div>
            </div>
          </div>
        </div> : ''
        }
      </div>
      
		)
	}
}

export default Head