import axios from 'axios'
import store from '../../store'

// const baseUrl = 'https://yq.baguatan.cn'
const baseUrl = ''


// 获取新闻
export function _getNews({ data } = {}) {
  return $http({
    data,
    url: '/api/website/new_list'
  })
}

// 获取新闻详情
export function _getNewsDl({ data } = {}) {
  return $http({
    data,
    url: '/api/website/new_content'
  })
}


// 验证手机号是否已存库
export function _checkPhone({ data } = {}) {
  return $http({
    data,
    url: '/api/website/phone'
  })
}

// 获取行业列表信息
export function _getHy() {
  return $http({
    url: '/api/website/industry'
  })
}

// 提交免费体验信息
export function _getcode({ data } = {}) {
  return $http({
    data,
    url: '/api/website/yzm'
  })
}

// 提交免费体验信息
export function _subFree({ data } = {}) {
  return $http({
    data,
    url: '/api/website/free_experience'
  })
}

// 提交供应商信息
export function _subGongying({ data } = {}) {
  return $http({
    data,
    url: '/api/website/supplier'
  })
}

// 提交服务商信息
export function _subFuwu({ data } = {}) {
  return $http({
    data,
    url: '/api/website/service_provider'
  })
}




// 封装http
function $http({ data, url, method } = {} ) {
  return new Promise((reslove, reject) => {
    // let sendData = new URLSearchParams()
    // for (let key in data) {
    //     console.log(key, data,data[key])
    //     sendData.append(key, data[key])
    // }
    axios({
      url: baseUrl + url,
      method: method || 'post',
      data,
    }).then((res) => {
      // eslint-disable-next-line
      if (res.data.code == 200) {
        reslove(res.data.data)
        // eslint-disable-next-line
      } else if (res.data.code == 400) {
        showToast(res.data.msg)
        reject(res.data.msg || '')
      } else {
        reject('数据错误')
      }
    }).catch((err) => {
      reject(err)
    });
  })
}

function showToast(value) {
  store.dispatch({
    type: 'showToast',
    value
  })
  setTimeout(() => {
    store.dispatch({type: 'hideToast'})
  }, 2000);
}