import React, { Component } from 'react';
import '../../assets/css/compent/head.scss'
import store from '../../store'

class Head extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: [{
        title: '首页',
        page: '/park'
      }, {
        title: '产品介绍',
        page: '/park/product'
      }, {
        title: '合作案例',
        page: '/park/example'
      }, {
        title: '服务商',
        page: '/park/channel'
      }, 
			// {
   //      title: '供应商',
   //      page: '/park/supplier'
   //    }, 
			{
        title: '新闻',
        page: '/park/news/industry/1'
      }, {
        title: '关于我们',
        page: '/park/about'
      }]
    }
  }
  componentDidMount() {
    setTimeout(() => {
      this.scrollFun()
      // document.body.onmousewheel = function () {
      //   return false;}
    }, 0);
    window.addEventListener('scroll', () => { 
      this.scrollFun()
    })
  }
  showPop() {
    store.dispatch({
      type: 'changePop_off',
      value: true
    })
  }
  // 屏幕滚动动画
  scrollFun() {
    let actDom = document.querySelectorAll('.actDom')
    let newDom = [...actDom]
    let domTop = []
    for (let i = 0; i < actDom.length; i++) {
      let wHeight = document.body.scrollTop || document.documentElement.scrollTop
      domTop[i] = 0
      do {
        
        domTop[i] += newDom[i].offsetTop
        newDom[i] = newDom[i].offsetParent //如果newDom[i] 节点 offsetParent
      } while (newDom[i].offsetParent);
      
      if (wHeight + window.innerHeight >= domTop[i] && !actDom[i].className.match('animated')) {
        actDom[i].className = actDom[i].className + ' animated ' + actDom[i].getAttribute('data-actcls')
        actDom[i].classList.remove('actDom')
        if (actDom[i].getAttribute('data-src')) {
          actDom[i].src = actDom[i].getAttribute('data-src')
        }
        if (actDom[i].getAttribute('data-lazypic') === '1') {
          let imgs = actDom[i].getElementsByTagName('img')
          for (let j = 0; j < imgs.length; j++) {
            if (imgs[j].getAttribute('data-src')) { 
              imgs[j].src = imgs[j].getAttribute('data-src')
            }
          }
        }
      }
    }
  }
  goAdmin() {
    // console.log(store.getState().adminUrl)
    // window.location.href = store.getState().adminUrl
    window.open(store.getState().adminUrl)
  }
  render() {
    return (
      <div className="pHead">
        <div className="pHead_con">
          <img className="phead_l" src={ require('../../assets/img/logo.png') } alt=""/>
          <div className='phead_r'>
            <ul>
              {
                this.state.menu.map((item, index) => {
                  return (
                    <li className={ `textBtn ${(this.props.nowPage === item.page || (this.props.nowPage.match('/park/news') && item.page === '/park/news/industry/1')) ? 'active' : ''}` } key={ index } onClick={ () => { this.props.goPage(item.page) } }>{ item.title }</li>
                  )
                })
              }
            </ul>
            <div className="phead_r_btn">
              <p className="btn" onClick={ () => { this.goAdmin() } }>登录</p>
              <div className="btn" onClick={ () => { this.showPop() } }>免费体验</div>
            </div>
          </div>
        </div>

      </div>
      
		)
	}
}

export default Head