import React, { Component } from 'react';
import '../assets/css/example.scss'
import Swiper from 'swiper/js/swiper.js'
import Company from './compent/company'
import store from '../store'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slideList: [{
        title: '大创工业园运营经理',
        text1: '你们的客服服务真的是太好了，我工作中操作系统遇到了问题，你们客服立马就给我解决掉了，非常配合。',
        text2: '感谢经理的肯定，我们也非常高兴得到了您的表扬啊。',
        img: require('../assets/img/example/example_avatar1.png')
      }, {
        title: '华研科创园运营总监',
        text1: '平台系统确实为我们解决了很多的问题，现在我做管理也比以前高效了很多，园区运营的所有数据很全面。',
        text2: '我们会更加努力做好为您做好系统的技术支撑，祝您园区发展越来越好。',
        img: require('../assets/img/example/example_avatar2.png')
      }, {
        title: '大狗创工厂负责人',
        text1: '几千种应用，确实满足了我们各种定制化的需求，而且为我们节省下一大笔购买系统的费用。',
        text2: '系统遇到任何问题，记得找我们客服哦，我们竭诚为您服务。',
        img: require('../assets/img/example/example_avatar3.png')
      }],
      ulLeft: 0,
      allWidth: 0
    }
  }
  slide(dir) {
    if (dir === 'left') {
      this.exswiper.slidePrev()
    } else {
      this.exswiper.slideNext()
    }
  }
  componentDidMount() {
    // let allWidth = this.state.slideList.length * 410
    // this.setState({
    //   allWidth,
    //   ulLeft: -allWidth
    // })
    this.exswiper = new Swiper('.swiper-container', {
      slidesPerView: 3,
      spaceBetween: 30,
      loop: true,
      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true,
      // },
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
    });
  }
  showPop() {
    store.dispatch({
      type: 'changePop_off',
      value: true
    })
  }
	render() {
		return(
      <div className="pExample">
        <div className="pExample_banner">
          <img src={require('../assets/img/example/example_bg.png')} alt="" />
          <div className="pExample_banner_con">
            <h6>YUANOS</h6>
            <div className="pExample_banner_text">让科技成就园区腾飞<br />
            为园区提供系统化的运营，管理，营收，物联网解决方案</div>
            <div className="pExample_banner_btn btn" onClick={ () => { this.showPop() } }>一键体验</div>
          </div>
        </div>

        <div className="pExample_title actDom" data-actcls="fadeInUp">您的同行也选择了YUANOS
        <div className="title_subtitle">Choice</div>
        </div>


        <div className="pExample_slide actDom" data-actcls="fadeInUp">
          <div className="swiper-container">
            {/* <div className="aa swiper-wrapper"> */}
              <div className="swiper-wrapper">
                {
                  this.state.slideList.map((item, index) => {
                    return (
                      <div className="swiper-slide" key={ index }>
                        <img src={item.img} alt="" />
                        <h6>{ item.title }</h6>
                        <p><span>A：</span>{ item.text1 }</p>
                        <p><span>B：</span>{ item.text2 }</p>
                      </div>
                    )
                    
                  })
                }
              </div>
            {/* </div> */}
            
          </div>
          <img src={ require("../assets/img/example/left.png") } alt="" className="pExample_slide_left btn" onClick={ () => { this.slide('left') } } />
          <img src={require("../assets/img/example/left.png")} alt="" className="pExample_slide_right btn"  onClick={ () => { this.slide('right') } } />
        </div>
        

        {/* <div className="pExample_slide actDom" data-actcls="fadeInUp">
          <img src={ require("../assets/img/example/left.png") } alt="" className="pExample_slide_left" onClick={ () => { this.slide('left') } } />
          <img src={require("../assets/img/example/left.png")} alt="" className="pExample_slide_right"  onClick={ () => { this.slide('right') } } />
          <div className="pExample_slide_list">
            <ul style={{ left: this.state.ulLeft + 'px' }}>
              {
                [1, 2, 3].map((item, index) => {
                  return (
                    this.state.slideList.map((item, index) => {
                      return (
                        <li key={ index }>
                          <img src={require("../assets/img/example/example_bg.png")} alt="" />
                          <h6>大创工业园运营经理{ index }</h6>
                          <p>A.你们的客服服务真的是太好了，我工作中操作系统遇到了问题，你们客服立马就给我解决掉了，非常配合。</p>
                          <p>B.感谢经理的肯定，我们也非常高兴得到了您的表扬啊。</p>
                        </li>
                      )
                      
                    })
                  )
                })
              }
            
            </ul>
          </div>
          
        </div> */}
        <div className="pExample_title actDom" data-actcls="fadeInUp">合作伙伴
        <div className="title_subtitle">Partner</div>
        </div>
        <Company />
      </div>		
		)
	}
}

export default Home