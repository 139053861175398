import React, { Component } from 'react';
import '../assets/css/about.scss'

class About extends Component {
  constructor(props) {
    super(props)
    this.state = {
      devList: [{
          year: '2014',
          text: '园区Saas项目成立'
        }, {
          year: '2015',
          text: '园区客户破100+'
        }, {
          year: '2017',
          text: '服务升级，增加智能硬件接入'
        }, {
          year: '2018',
          text: '园区客户突破2000+'
        }, {
          year: '2019',
          text: '发展开放大平台战略，品牌升级YUANOS'
        }
      ]
    }
  }
	render() {
		return(
      <div className="pAbout">
        <div className="pAbout_banner">
          <img src={require('../assets/img/about/about_banner.png')} alt="" />
          <div className="pAbout_banner_con">
            <h6>公司介绍</h6>
            <p className="pAbout_banner_text">yuanos园区平台saas系统是杭州搜雪网络有限公司自主研发国内首家结合区块链技术的开放型平台系统，六年的技术沉淀为园区管理机构提供SaaS服务，致力于解决园区资产管理，招商管理，营收增长，园区生态，物联网产业转型。运用区块链技术赋能园区，帮助中小园区智慧化产业升级。截至目前yuanos平台系统已经累计服务超过2000+园区，全国范围内设立分公司，1000人技术团队为当地园区客户和加盟商提供技术和服务支持，分公司遍布杭州，北京，上海，广州，深圳，成都，南京，济南等13省市，业务还在不断扩展中。欢迎各大客户和意向合作商莅临指导。</p>
          </div>
        </div>

        <h6 className="pAbout_title actDom" data-actcls="fadeInUp">发展历程
        <div className="title_subtitle">course</div>
        </h6>
        {/* <div className="pAbout_devbox"> */}
          {/* <ul className="pAbout_dev actDom" data-actcls="fadeInUp">
            {
              this.state.devList.map((item, index) => {
                return (
                  <li className="pAbout_dev_item" key={ index }>
                    <div className="pAbout_dev_dot"><i></i></div>
                    <div className="pAbout_dev_year">{ item.year }</div>
                    <div className="pAbout_dev_text">{item.text}</div> 
                    <div className="pAbout_dev_line"></div>
                    <img src={ require('../assets/img/about/about_line.png') } className="pAbout_dev_line" alt=""/>
                  </li>
                )
              })
            }
            <li className="pAbout_dev_outline"></li>
            <li className="pAbout_dev_map"></li> 
          </ul> */}
        <ul className="pAbout_dev actDom" data-actcls="fadeInUp">
            { 
              this.state.devList.map((item, index) => {
                return (
                  <li className="pAbout_dev_item" key={ index }>
                    <div className="pAbout_dev_dot"><i></i></div>
                    {/* <div className="pAbout_dev_year">{ item.year }</div> */}
                    <div className="pAbout_dev_text">
                      <h6>{item.year}年</h6>
                      <div>{item.text}</div>
                    </div> 
                    <div className="pAbout_dev_line"></div>
                    <img src={ require('../assets/img/about/about_line.png') } className="pAbout_dev_line" alt=""/>
                  </li>
                )
              })
            }
            <li className="pAbout_dev_outline"></li>
            <li className="pAbout_dev_map"></li> 
          </ul>
        {/* </div> */}
        
      </div>				
		)
	}
}

export default About 