import React, {Component} from 'react'
import Sortpage from './compent/sortpage.js'
import '../assets/css/news.scss'

import { _getNews } from '../assets/js/request.js'

class News extends Component {
    constructor(props) {
      super(props)
      this.state = {
        pageConfig: {
            groupCount: 5, //页码分组，显示7个页码，其余用省略号显示
            startPage: Number(props.match.params.page),  //分组开始页码
            totalPage: 0 //总页数
        },
        titleList: [{
          title: '行业资讯',
          type: 'industry'
        }, {
          title: '新闻动态',
          type: 'act'
        }],
        page: props.match.params.page,
        type: 'industry',
        newsList: []
      }
      
  }
  componentDidMount() {
    this.getinfo()
  }

  getinfo() {
    _getNews({
      data: {
        cate: this.state.type === 'industry' ? 1 : 2,
        page: Number(this.state.page)
      }
    }).then(res => {
      this.setState({
        newsList: res.data,
        pageConfig: {
          groupCount: 5, //页码分组，显示7个页码，其余用省略号显示
          startPage: Number(res.current_page),  //分组开始页码
          totalPage: res.last_page //总页数
      },
      })
    })
  }
  // 跳转页面
  getCurrentPage(currentPage) {
    if (currentPage !== this.props.match.params.page) {
      this.props.history.push(`/park/news/${this.state.type}/${currentPage}`)
    }
  }
  componentDidUpdate() {
    
    if (this.props.match.params.type !== this.state.type || this.props.match.params.page !== this.state.page) {
      console.log('当前页面:', this.props.match.params)
      this.setState({
        type: this.props.match.params.type,
        page: this.props.match.params.page
      }, () => {
        this.getinfo()
      })
      
    }
  }
  // 前往详情
  goDl(id) {
    // if (page !== this.state.page) {
      this.props.history.push(`/park/newsDetail/${id}`)
    // }
    
  }
  // 选择类别
  choseTitle(index) {
    let type = index === 0 ? 'industry' : 'act'
    if (type !== this.state.type) {
      this.props.history.push(`/park/news/${type}/1`)
    }
    
  }
  render() {
      return (
        <div className="newsPage">
          <img src={ require('../assets/img/news_banner.png') } className="newsPage_banner" alt=""/>
          <div className="news_main">
            <div className="new_title">
              {
                this.state.titleList.map((item, index) => {
                  return (
                    <div key={index} className={ this.props.match.params.type === item.type ? 'actTitle' : ''  } onClick={ () => { this.choseTitle(index) } }>{ item.title }</div>
                  )
                })
              }
              <div></div>
            </div>

            <ul className="news_list">
              {
                this.state.newsList.map((item, index) => {
                  return (
                    <li key={index}>
                      <div className="news_list_con">
                        <img src={item.image} alt="" />
                        <div className="new_list_r">
                          <div className="new_list_title">{ item.title }</div>
                          <div className="new_list_des">好的经验加好的系统,才能让服务更佳贴心</div>
                          <div className="new_list_btn btn" onClick={() => this.goDl(item.id)}>查看全文</div>
                        </div>
                      </div>
                      <div className="news_list_time">2019-12-13</div>
                    </li>
                  )
                })
              }
            </ul>
            <div className="news_page">
              {
                this.state.pageConfig.totalPage > 0 ? <Sortpage nowPage={ this.state.page } pageConfig={this.state.pageConfig} pageCallbackFn={(data) => this.getCurrentPage(data)}/> : ''
              }
              
            </div>
          </div> 
          
          
          
        </div>

      )
    }
}
export default News